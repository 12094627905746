/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Type of TaskApprovalRule
 */
export enum AutoApprovalRuleEnum {
  ALWAYS_APPROVE = "ALWAYS_APPROVE",
  DEFAULT = "DEFAULT",
  NEVER_APPROVE = "NEVER_APPROVE",
}

/**
 * bank account type
 */
export enum BankAccountTypeEnum {
  DK = "DK",
  INTERNATIONAL = "INTERNATIONAL",
}

/**
 * Allowed contract signature statuses
 */
export enum ContractSignatureStatusEnum {
  ERROR = "ERROR",
  NONE = "NONE",
  REJECTED = "REJECTED",
  SENT = "SENT",
  SIGNED = "SIGNED",
}

/**
 * Allowed contract statuses
 */
export enum ContractStatusEnum {
  CHANGES_REQUESTED = "CHANGES_REQUESTED",
  DRAFT = "DRAFT",
  NO_SIGNATURE_NEEDED = "NO_SIGNATURE_NEEDED",
  SENT = "SENT",
  SIGNED = "SIGNED",
}

/**
 * Allowed contract template statuses
 */
export enum ContractTemplateStatusEnum {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
}

/**
 * Type of contract
 */
export enum ContractTypeEnum {
  ASSOCIATION_AGREEMENT = "ASSOCIATION_AGREEMENT",
  EMPLOYMENT_CONTRACT = "EMPLOYMENT_CONTRACT",
}

/**
 * Type of customer
 */
export enum CustomerTypeEnum {
  BUSINESS = "BUSINESS",
  PRIVATE = "PRIVATE",
}

/**
 * Allowed deduction statuses
 */
export enum DeductionStatusEnum {
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
  DELETED_BY_USER = "DELETED_BY_USER",
  DRAFT = "DRAFT",
  SENT = "SENT",
}

/**
 * Allowed email statuses
 */
export enum EmailLogStatusEnum {
  DELIVERED = "DELIVERED",
  FAILED = "FAILED",
  PENDING = "PENDING",
  SEEN = "SEEN",
  SENT = "SENT",
}

/**
 * Allowed invoice statuses
 */
export enum InvoiceStatusEnum {
  APPROVED_BY_COMPANY = "APPROVED_BY_COMPANY",
  AWAITING_TASK_APPROVAL = "AWAITING_TASK_APPROVAL",
  CANCELLED = "CANCELLED",
  COMPANY_DISPUTE = "COMPANY_DISPUTE",
  DEBT_COLLECTION = "DEBT_COLLECTION",
  DENIED_BY_COMPANY = "DENIED_BY_COMPANY",
  DRAFT = "DRAFT",
  LATE_PAYMENT = "LATE_PAYMENT",
  MORE_INFO_NEEDED = "MORE_INFO_NEEDED",
  PAID = "PAID",
  PAYMENT_ON_HOLD = "PAYMENT_ON_HOLD",
  PAYMENT_RECEIVED = "PAYMENT_RECEIVED",
  PENDING = "PENDING",
  SALARY_PAID = "SALARY_PAID",
  SALARY_PAID_CUSTOMER_NOT_PAID = "SALARY_PAID_CUSTOMER_NOT_PAID",
  SALARY_PAID_CUSTOMER_PAID = "SALARY_PAID_CUSTOMER_PAID",
  SENT = "SENT",
  SENT_TO_COMPANY = "SENT_TO_COMPANY",
  SENT_TO_COMPANY_AWAITING_APPROVAL = "SENT_TO_COMPANY_AWAITING_APPROVAL",
  SENT_TO_COMPANY_CONTRACT_MADE = "SENT_TO_COMPANY_CONTRACT_MADE",
  SENT_TO_COMPANY_NEEDS_CONTRACT = "SENT_TO_COMPANY_NEEDS_CONTRACT",
}

/**
 * Type of customer language
 */
export enum LanguageEnum {
  DA = "DA",
  EN = "EN",
}

/**
 * Allowed mileage request statuses
 */
export enum MileageRequestStatusEnum {
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
  EXPIRED = "EXPIRED",
  SENT = "SENT",
}

/**
 * Allowed mileage statuses
 */
export enum MileageStatusEnum {
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
  DRAFT = "DRAFT",
  SENT = "SENT",
}

/**
 * How frequently notification emails are sent out to the user
 */
export enum NotificationFrequencyEnum {
  DAILY = "DAILY",
  INSTANTLY = "INSTANTLY",
  MONTHLY = "MONTHLY",
  NEVER = "NEVER",
  WEEKLY = "WEEKLY",
}

/**
 * Allowed offer statuses
 */
export enum OfferStatusEnum {
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
  DRAFT = "DRAFT",
  SENT = "SENT",
  VIEWED = "VIEWED",
}

/**
 * Allowed payment terms
 */
export enum PaymentTermEnum {
  CURRENT_MONTH_PLUS = "CURRENT_MONTH_PLUS",
  ONGOING_WEEK_PLUS = "ONGOING_WEEK_PLUS",
  OTHER = "OTHER",
  TASK_END_PLUS = "TASK_END_PLUS",
}

/**
 * Type of payroll
 */
export enum PayrollDateTypeEnum {
  Payroll = "Payroll",
  Salary = "Salary",
}

/**
 * Status of Zenegy PayrollUser
 */
export enum PayrollUserStatusEnum {
  FINALIZED = "FINALIZED",
  OPEN = "OPEN",
  PENDING = "PENDING",
}

/**
 * Allowed price Categories
 */
export enum PriceCategoryEnum {
  DAILY_RATE = "DAILY_RATE",
  HOURLY_RATE = "HOURLY_RATE",
  OTHER = "OTHER",
  PER_ITEM = "PER_ITEM",
  PROJECT_PRICE = "PROJECT_PRICE",
}

/**
 * Allowed invoice statuses
 */
export enum RecordTypeEnum {
  COMPANY = "COMPANY",
  CONTRACT = "CONTRACT",
  CONTRACT_SIGNATURE = "CONTRACT_SIGNATURE",
  CONTRACT_TEMPLATE = "CONTRACT_TEMPLATE",
  CONTRACT_TEMPLATE_RULE = "CONTRACT_TEMPLATE_RULE",
  COOPERATION_AGREEMENT = "COOPERATION_AGREEMENT",
  CUSTOMER = "CUSTOMER",
  DEDUCTION = "DEDUCTION",
  INVOICE = "INVOICE",
  INVOICE_LINE = "INVOICE_LINE",
  MILEAGE = "MILEAGE",
  MILEAGE_REQUEST = "MILEAGE_REQUEST",
  OFFER = "OFFER",
  OFFER_SERVICE = "OFFER_SERVICE",
  OFFER_SIGNATURE = "OFFER_SIGNATURE",
  PAYROLL = "PAYROLL",
  PAYROLL_USER = "PAYROLL_USER",
  REFERRAL = "REFERRAL",
  REWARD = "REWARD",
  SALARY = "SALARY",
  TASK = "TASK",
  USER = "USER",
  WORK_LOG = "WORK_LOG",
}

/**
 * Allowed referral statuses
 */
export enum ReferralStatusEnum {
  NONE = "NONE",
  PAID_INVOICE = "PAID_INVOICE",
  SENT_INVOICE = "SENT_INVOICE",
  SIGNED_UP = "SIGNED_UP",
}

/**
 * Users assigned role
 */
export enum RoleEnum {
  ADMIN = "ADMIN",
  EMPLOYEE = "EMPLOYEE",
  SYSTEM = "SYSTEM",
  USER = "USER",
}

/**
 * Details about when salary needs to be paid for the user
 */
export enum SalaryPaymentTypeEnum {
  EARLY = "EARLY",
  FIRST_OF_MONTH = "FIRST_OF_MONTH",
  LAST_OF_MONTH = "LAST_OF_MONTH",
  ON_PAYMENT = "ON_PAYMENT",
  TIMED = "TIMED",
  VALUE = "VALUE",
  VIA_INVOICE = "VIA_INVOICE",
}

/**
 * Allowed salary statuses
 */
export enum SalaryStatusEnum {
  CANCELLED = "CANCELLED",
  DRAFT = "DRAFT",
  PAID = "PAID",
  SENT = "SENT",
}

export enum SortOrder {
  asc = "asc",
  desc = "desc",
}

/**
 * Support post type
 */
export enum SupportPostEnum {
  FAQ = "FAQ",
  FAQ_HEADER = "FAQ_HEADER",
  ONBOARDING_POST = "ONBOARDING_POST",
}

/**
 * Allowed task payment types
 */
export enum TaskPaymentTypeEnum {
  PER_DAY = "PER_DAY",
  PER_HOUR = "PER_HOUR",
  PROJECT_PRICE = "PROJECT_PRICE",
}

/**
 * Allowed task statuses
 */
export enum TaskStatusEnum {
  APPROVED = "APPROVED",
  CANCELED = "CANCELED",
  CHANGES_REQUESTED = "CHANGES_REQUESTED",
  COMPLETED = "COMPLETED",
  DENIED = "DENIED",
  DRAFT = "DRAFT",
  MORE_INFO_NEEDED = "MORE_INFO_NEEDED",
  PENDING = "PENDING",
  SENT = "SENT",
  SENT_TO_CUSTOMER_AWAITING_APPROVAL = "SENT_TO_CUSTOMER_AWAITING_APPROVAL",
  SENT_TO_CUSTOMER_SEEN_BY_CUSTOMER = "SENT_TO_CUSTOMER_SEEN_BY_CUSTOMER",
}

/**
 * Type of time registration on a task
 */
export enum TaskTimeRegistrationTypeEnum {
  MANAGED = "MANAGED",
  SELF_ORGANIZED = "SELF_ORGANIZED",
}

/**
 * The tax card used for the user
 */
export enum TaxCardEnum {
  MAIN = "MAIN",
  SECONDARY = "SECONDARY",
}

/**
 * Allowed work log statuses
 */
export enum WorkLogStatusEnum {
  DRAFT = "DRAFT",
  SENT = "SENT",
}

export interface AddressCreateInputArgs {
  address: string;
  default: boolean;
  city: string;
  region?: string | null;
  postalCode: string;
  country: ConnectUniqueInputArgs;
}

export interface AddressOrderByInputArgs {
  active?: SortOrder | null;
  address?: SortOrder | null;
  city?: SortOrder | null;
  countryId?: SortOrder | null;
  country?: CountryOrderByInputArgs | null;
  id?: SortOrder | null;
  region?: SortOrder | null;
  createdDate?: SortOrder | null;
  updatedDate?: SortOrder | null;
  userId?: SortOrder | null;
  user?: UserOrderByInputArgs | null;
  postalCode?: SortOrder | null;
}

export interface AddressUpdateInputArgs {
  active?: boolean | null;
  id: any;
  default?: boolean | null;
  address?: string | null;
  city?: string | null;
  region?: string | null;
  postalCode?: string | null;
  country?: ConnectUniqueInputArgs | null;
}

export interface AddressWhereInputArgs {
  active?: BoolFilter | null;
  address?: StringFilter | null;
  city?: StringFilter | null;
  country?: CountryWhereInputArgs | null;
  id?: BigIntFilter | null;
  userId?: BigIntFilter | null;
  region?: StringFilter | null;
  createdDate?: DateTimeFilter | null;
  updatedDate?: DateTimeFilter | null;
  postalCode?: StringFilter | null;
}

export interface AuditOrderByInputArgs {
  createdDate?: SortOrder | null;
}

export interface AuditWhereInputArgs {
  id?: BigIntFilter | null;
  createdDate?: DateTimeFilter | null;
  updatedDate?: DateTimeFilter | null;
  recordId?: BigIntFilter | null;
  recordType?: RecordTypeFilter | null;
  userId?: BigIntFilter | null;
  event?: StringFilter | null;
}

export interface BigIntFilter {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  notIn?: any[] | null;
  not?: any | null;
}

export interface BookInvoiceInputArgs {
  id: any;
  sendViaEmail?: boolean | null;
  sendViaEAN?: boolean | null;
}

export interface BoolFilter {
  equals?: boolean | null;
  not?: boolean | null;
}

export interface CompanyOrderByInputArgs {
  id?: SortOrder | null;
  name?: SortOrder | null;
  createdDate?: SortOrder | null;
  updatedDate?: SortOrder | null;
  vatId?: SortOrder | null;
}

export interface CompanyUpdateInputArgs {
  allowEarlyPayment?: boolean | null;
  allowDeductions?: boolean | null;
  allowMileages?: boolean | null;
  feeRate?: number | null;
  earlyPaymentFeeRate?: number | null;
  disableOverdueReminders?: boolean | null;
  vacationPayment?: boolean | null;
  earlyPayment?: boolean | null;
  taskAutoApprovalRule?: AutoApprovalRuleEnum | null;
  isPartner?: boolean | null;
}

export interface CompanyWhereInputArgs {
  vatId?: BigIntFilter | null;
  id?: BigIntFilter | null;
  name?: StringFilter | null;
  createdDate?: DateTimeFilter | null;
  updatedDate?: DateTimeFilter | null;
  isPartner?: BoolFilter | null;
  query?: string | null;
}

export interface ConnectDisconnectUniqueInputArgs {
  connect: WhereUniqueInputArgs;
}

export interface ConnectUniqueInputArgs {
  connect: WhereUniqueInputArgs;
}

export interface ContractOrderByInputArgs {
  id?: SortOrder | null;
  createdDate?: SortOrder | null;
  updatedDate?: SortOrder | null;
  user?: UserOrderByInputArgs | null;
  userId?: SortOrder | null;
  status?: SortOrder | null;
  title?: SortOrder | null;
}

export interface ContractStatusFilter {
  equals?: ContractStatusEnum | null;
  in?: ContractStatusEnum[] | null;
  notIn?: ContractStatusEnum[] | null;
  not?: ContractStatusEnum | null;
}

export interface ContractTemplateCreateInputArgs {
  name: string;
  title: string;
  intro: string;
  content: string;
  contractType: ContractTypeEnum;
  rules: ContractTemplateCreateNestedRuleInputArgs;
}

export interface ContractTemplateCreateNestedRuleInputArgs {
  create: ContractTemplateRuleCreateInputArgs[];
}

export interface ContractTemplateOrderByInputArgs {
  id?: SortOrder | null;
  createdDate?: SortOrder | null;
  updatedDate?: SortOrder | null;
  user?: UserOrderByInputArgs | null;
  title?: SortOrder | null;
  name?: SortOrder | null;
}

export interface ContractTemplateRuleCreateInputArgs {
  key: string;
  value: string;
}

export interface ContractTemplateRuleUpdateInputArgs {
  key?: string | null;
  value?: string | null;
}

export interface ContractTemplateUpdateInputArgs {
  name?: string | null;
  title?: string | null;
  intro?: string | null;
  content?: string | null;
  contractType?: ContractTypeEnum | null;
  status?: ContractTemplateStatusEnum | null;
  rules?: ContractTemplateUpdateNestedRulesInputArgs | null;
  active?: boolean | null;
}

export interface ContractTemplateUpdateNestedRuleInputArgs {
  data: ContractTemplateRuleUpdateInputArgs;
  where: WhereUniqueInputArgs;
}

export interface ContractTemplateUpdateNestedRulesInputArgs {
  update?: ContractTemplateUpdateNestedRuleInputArgs[] | null;
  create?: ContractTemplateRuleCreateInputArgs[] | null;
  delete?: WhereUniqueInputArgs[] | null;
}

export interface ContractTemplateWhereInputArgs {
  id?: BigIntFilter | null;
  createdDate?: DateTimeFilter | null;
  updatedDate?: DateTimeFilter | null;
  user?: UserWhereInputArgs | null;
  query?: string | null;
  active?: BoolFilter | null;
}

export interface ContractUpdateInputArgs {
  active?: boolean | null;
  requestedChanges?: string | null;
  title?: string | null;
  intro?: string | null;
  content?: string | null;
  status?: ContractStatusEnum | null;
}

export interface ContractWhereInputArgs {
  id?: BigIntFilter | null;
  active?: BoolFilter | null;
  createdDate?: DateTimeFilter | null;
  updatedDate?: DateTimeFilter | null;
  status?: ContractStatusFilter | null;
  invoiceIds?: (any | null)[] | null;
  user?: UserWhereInputArgs | null;
  query?: string | null;
}

export interface CooperationAgreementCreateInputArgs {
  active: boolean;
  startDate: any;
  endDate?: any | null;
  customerId: any;
  openEnded: boolean;
  terminationAgreement: string;
  taskDescription: string;
  extraWork: boolean;
  extraWorkNotification?: string | null;
  extraWorkNotificationOther?: string | null;
  equipmentSupplied?: boolean | null;
  equipmentDetails?: string | null;
  specialConditions: string;
  paymentType: string;
  paymentTerm: string;
  paymentTermOther?: string | null;
  paymentTermDays: number;
  paymentTermSpecial: string;
  deliverables?: CooperationAgreementCreateNestedDeliverableInputArgs | null;
  createAsUserId?: any | null;
}

export interface CooperationAgreementCreateNestedDeliverableInputArgs {
  create: DeliverableCreateInputArgs[];
}

export interface CooperationAgreementOrderByInputArgs {
  startDate?: SortOrder | null;
  endDate?: SortOrder | null;
  user?: UserOrderByInputArgs | null;
  createdDate?: SortOrder | null;
  updatedDate?: SortOrder | null;
  customer?: CustomerOrderByInputArgs | null;
  id?: SortOrder | null;
}

export interface CooperationAgreementUpdateInputArgs {
  active?: boolean | null;
  startDate?: any | null;
  endDate?: any | null;
  openEnded?: boolean | null;
  customerId?: any | null;
  terminationAgreement?: string | null;
  taskDescription?: string | null;
  extraWork?: boolean | null;
  extraWorkNotification?: string | null;
  extraWorkNotificationOther?: string | null;
  equipmentSupplied?: boolean | null;
  equipmentDetails?: string | null;
  specialConditions?: string | null;
  paymentType?: string | null;
  paymentTerm?: string | null;
  paymentTermOther?: string | null;
  paymentTermDays?: number | null;
  paymentTermSpecial?: string | null;
  deliverables?: CooperationAgreementUpdateNestedDeliverablesInputArgs | null;
}

export interface CooperationAgreementUpdateNestedDeliverableInputArgs {
  data: DeliverableUpdateInputArgs;
  where: WhereUniqueInputArgs;
}

export interface CooperationAgreementUpdateNestedDeliverablesInputArgs {
  update?: CooperationAgreementUpdateNestedDeliverableInputArgs[] | null;
  create?: DeliverableCreateInputArgs[] | null;
  delete?: WhereUniqueInputArgs[] | null;
}

export interface CooperationAgreementWhereInputArgs {
  id?: BigIntFilter | null;
  createdDate?: DateTimeFilter | null;
  updatedDate?: DateTimeFilter | null;
  startDate?: DateTimeFilter | null;
  user?: UserWhereInputArgs | null;
  customer?: CustomerWhereInputArgs | null;
  active?: BoolFilter | null;
  query?: string | null;
}

export interface CountryOrderByInputArgs {
  id?: SortOrder | null;
  code?: SortOrder | null;
  name_en?: SortOrder | null;
  name_da?: SortOrder | null;
  createdDate?: SortOrder | null;
  updatedDate?: SortOrder | null;
  active?: SortOrder | null;
}

export interface CountryWhereInputArgs {
  id?: BigIntFilter | null;
  code?: StringFilter | null;
  name?: StringFilter | null;
  createdDate?: DateTimeFilter | null;
  updatedDate?: DateTimeFilter | null;
  active?: BoolFilter | null;
}

export interface CreateContractInputArgs {
  userId: any;
}

export interface CurrencyWhereUniqueInputArgs {
  code?: string | null;
}

export interface CustomerCreateInputArgs {
  type: CustomerTypeEnum;
  contact: string;
  vatId?: string | null;
  ean?: any | null;
  email: string;
  sendReminders: boolean;
  name: string;
  paymentDueDays?: number | null;
  language?: LanguageEnum | null;
  phoneNumber: string;
  address: CustomerCreateNestedAddressInputArgs;
  createAsUserId?: any | null;
}

export interface CustomerCreateNestedAddressInputArgs {
  create: AddressCreateInputArgs;
}

export interface CustomerOrderByInputArgs {
  active?: SortOrder | null;
  type?: SortOrder | null;
  contact?: SortOrder | null;
  vatId?: SortOrder | null;
  ean?: SortOrder | null;
  email?: SortOrder | null;
  id?: SortOrder | null;
  name?: SortOrder | null;
  paymentDueDays?: SortOrder | null;
  phoneNumber?: SortOrder | null;
  createdDate?: SortOrder | null;
  updatedDate?: SortOrder | null;
  userId?: SortOrder | null;
  user?: UserOrderByInputArgs | null;
  addressId?: SortOrder | null;
  address?: AddressOrderByInputArgs | null;
}

export interface CustomerTypeFilter {
  equals?: CustomerTypeEnum | null;
  in?: CustomerTypeEnum[] | null;
  notIn?: CustomerTypeEnum[] | null;
  not?: CustomerTypeEnum | null;
}

export interface CustomerUpdateInputArgs {
  active?: boolean | null;
  type?: CustomerTypeEnum | null;
  contact?: string | null;
  vatId?: string | null;
  ean?: any | null;
  email?: string | null;
  sendReminders?: boolean | null;
  name?: string | null;
  paymentDueDays?: number | null;
  language?: LanguageEnum | null;
  phoneNumber?: string | null;
  address?: CustomerUpdateNestedAddressInputArgs | null;
  allowEarlyPayment?: boolean | null;
  allowDeductions?: boolean | null;
  allowMileages?: boolean | null;
  feeRate?: number | null;
  earlyPaymentFeeRate?: number | null;
  disableOverdueReminders?: boolean | null;
  vacationPayment?: boolean | null;
  taskAutoApprovalRule?: AutoApprovalRuleEnum | null;
}

export interface CustomerUpdateNestedAddressInputArgs {
  update: AddressUpdateInputArgs;
}

export interface CustomerWhereInputArgs {
  active?: BoolFilter | null;
  type?: CustomerTypeFilter | null;
  contact?: StringFilter | null;
  vatId?: BigIntFilter | null;
  ean?: BigIntFilter | null;
  email?: StringFilter | null;
  sendReminders?: BoolFilter | null;
  id?: BigIntFilter | null;
  name?: StringFilter | null;
  paymentDueDays?: IntFilter | null;
  phoneNumber?: StringFilter | null;
  createdDate?: DateTimeFilter | null;
  updatedDate?: DateTimeFilter | null;
  user?: UserWhereInputArgs | null;
  private?: BoolFilter | null;
  address?: AddressWhereInputArgs | null;
  companyId?: BigIntFilter | null;
  query?: string | null;
}

export interface DateTimeFilter {
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  notIn?: string[] | null;
  not?: string | null;
}

export interface DeductionCreateInputArgs {
  description: string;
  status: DeductionStatusEnum;
  createAsUserId?: any | null;
  total: any;
  includeVat: boolean;
  currency: string;
  deductionNumber?: string | null;
  taskId: any;
}

export interface DeductionListRelationWhereArgs {
  every?: DeductionWhereInputArgs | null;
  some?: DeductionWhereInputArgs | null;
  none?: DeductionWhereInputArgs | null;
}

export interface DeductionOrderByInputArgs {
  id?: SortOrder | null;
  description?: SortOrder | null;
  createdDate?: SortOrder | null;
  updatedDate?: SortOrder | null;
  userId?: SortOrder | null;
  user?: UserOrderByInputArgs | null;
  status?: SortOrder | null;
  total?: SortOrder | null;
}

export interface DeductionRejectionReasonCreateInputArgs {
  title: string;
  description: string;
  language: LanguageEnum;
}

export interface DeductionRejectionReasonUpdateInputArgs {
  title?: string | null;
  description?: string | null;
}

export interface DeductionRejectionReasonWhereInputArgs {
  id?: BigIntFilter | null;
  language?: LanguageEnum | null;
}

export interface DeductionStatusFilter {
  equals?: DeductionStatusEnum | null;
  in?: DeductionStatusEnum[] | null;
  notIn?: DeductionStatusEnum[] | null;
  not?: DeductionStatusEnum | null;
}

export interface DeductionUpdateInputArgs {
  description?: string | null;
  active?: boolean | null;
  status?: DeductionStatusEnum | null;
  total?: any | null;
  includeVat?: boolean | null;
  currency?: string | null;
  taskId?: any | null;
}

export interface DeductionWhereInputArgs {
  id?: BigIntFilter | null;
  createdDate?: DateTimeFilter | null;
  updatedDate?: DateTimeFilter | null;
  status?: DeductionStatusFilter | null;
  active?: BoolFilter | null;
  description?: StringFilter | null;
  user?: UserWhereInputArgs | null;
  salaryId?: BigIntFilter | null;
  taskId?: BigIntFilter | null;
  task?: TaskWhereInputArgs | null;
  OR?: (DeductionWhereInputArgs | null)[] | null;
  query?: string | null;
}

export interface DeliverableCreateInputArgs {
  description: string;
}

export interface DeliverableUpdateInputArgs {
  description?: string | null;
}

export interface EmailLogOrderByInputArgs {
  id?: SortOrder | null;
  sentDate?: SortOrder | null;
  recipient?: SortOrder | null;
  userId?: SortOrder | null;
  user?: UserOrderByInputArgs | null;
  status?: SortOrder | null;
}

export interface EmailLogStatusFilter {
  equals?: EmailLogStatusEnum | null;
  in?: EmailLogStatusEnum[] | null;
  notIn?: EmailLogStatusEnum[] | null;
  not?: EmailLogStatusEnum | null;
}

export interface EmailLogWhereInputArgs {
  id?: BigIntFilter | null;
  createdDate?: DateTimeFilter | null;
  updatedDate?: DateTimeFilter | null;
  sentDate?: DateTimeFilter | null;
  status?: EmailLogStatusFilter | null;
  recipient?: StringFilter | null;
  subject?: StringFilter | null;
  message?: StringFilter | null;
  user?: UserWhereInputArgs | null;
  OR?: (EmailLogWhereInputArgs | null)[] | null;
  query?: string | null;
}

export interface FloatFilter {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  notIn?: number[] | null;
  not?: number | null;
}

export interface IntFilter {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  notIn?: number[] | null;
  not?: number | null;
}

export interface InvoiceBulkUpdateInputArgs {
  data?: InvoiceUpdateInputArgs | null;
  where?: WhereUniqueInputArgs | null;
}

export interface InvoiceCreateInputArgs {
  companyId?: any | null;
  customer: InvoiceCreateNestedCustomerInputArgs;
  customerContact: string;
  customerEmail: string;
  sendInvoiceCopyTo?: string | null;
  task?: InvoiceCreateNestedTaskInputArgs | null;
  invoiceDate: any;
  reference?: string | null;
  startDate: any;
  endDate: any;
  jobType: InvoiceCreateNestedJobTypeInputArgs;
  hoursWorked: any;
  useCredit: boolean;
  paymentDueDays: number;
  currency: string;
  vacationPayment: boolean;
  includeVat: boolean;
  earlyPayment: boolean;
  lines: InvoiceCreateNestedLineInputArgs;
  mileages?: InvoiceCreateNestedMileagesInputArgs | null;
  termsAccepted: boolean;
  note?: string | null;
  status: InvoiceStatusEnum;
  createAsUserId?: any | null;
}

export interface InvoiceCreateNestedCustomerInputArgs {
  connect?: WhereUniqueInputArgs | null;
  create?: CustomerCreateInputArgs | null;
}

export interface InvoiceCreateNestedJobTypeInputArgs {
  connect: WhereUniqueInputArgs;
}

export interface InvoiceCreateNestedLineInputArgs {
  create: InvoiceLineCreateInputArgs[];
}

export interface InvoiceCreateNestedMileagesInputArgs {
  connect?: WhereUniqueInputArgs[] | null;
}

export interface InvoiceCreateNestedTaskInputArgs {
  connect: WhereUniqueInputArgs;
}

export interface InvoiceLineCreateInputArgs {
  description: string;
  unitPrice: number;
  quantity: number;
  discountPercentage?: number | null;
  unit?: string | null;
  index: number;
}

export interface InvoiceLineUpdateInputArgs {
  active?: boolean | null;
  description?: string | null;
  unitPrice?: number | null;
  discountPercentage?: number | null;
  unit?: string | null;
  index?: number | null;
  quantity?: number | null;
}

export interface InvoiceListRelationWhereArgs {
  every?: InvoiceWhereInputArgs | null;
  some?: InvoiceWhereInputArgs | null;
  none?: InvoiceWhereInputArgs | null;
}

export interface InvoiceOrderByInputArgs {
  active?: SortOrder | null;
  vacationPayment?: SortOrder | null;
  currency?: SortOrder | null;
  customerId?: SortOrder | null;
  visibleId?: SortOrder | null;
  customer?: CustomerOrderByInputArgs | null;
  endDate?: SortOrder | null;
  hoursWorked?: SortOrder | null;
  id?: SortOrder | null;
  note?: SortOrder | null;
  invoiceDate?: SortOrder | null;
  paymentDueDays?: SortOrder | null;
  reference?: SortOrder | null;
  createdDate?: SortOrder | null;
  updatedDate?: SortOrder | null;
  startDate?: SortOrder | null;
  status?: SortOrder | null;
  taskId?: SortOrder | null;
  task?: TaskOrderByInputArgs | null;
  termsAccepted?: SortOrder | null;
  userId?: SortOrder | null;
  user?: UserOrderByInputArgs | null;
  total?: SortOrder | null;
  includeVat?: SortOrder | null;
  jobTypeId?: SortOrder | null;
  jobType?: JobTypeOrderByInputArgs | null;
}

export interface InvoiceStatusFilter {
  equals?: InvoiceStatusEnum | null;
  in?: InvoiceStatusEnum[] | null;
  notIn?: InvoiceStatusEnum[] | null;
  not?: InvoiceStatusEnum | null;
}

export interface InvoiceUpdateInputArgs {
  customer?: InvoiceUpdateNestedCustomerInputArgs | null;
  customerContact?: string | null;
  customerEmail?: string | null;
  sendInvoiceCopyTo?: string | null;
  task?: InvoiceUpdateNestedTaskInputArgs | null;
  invoiceDate?: any | null;
  reference?: string | null;
  startDate?: any | null;
  endDate?: any | null;
  jobType?: InvoiceUpdateNestedJobTypeInputArgs | null;
  hoursWorked?: any | null;
  useCredit?: boolean | null;
  paymentDueDays?: number | null;
  currency?: string | null;
  vacationPayment?: boolean | null;
  includeVat?: boolean | null;
  earlyPayment?: boolean | null;
  paidAmountDkk?: any | null;
  allowanceDkk?: any | null;
  legacyMileageDkk?: any | null;
  legacyDeductionsDkk?: any | null;
  lines?: InvoiceUpdateNestedLinesInputArgs | null;
  mileages?: InvoiceUpdateNestedMileagesInputArgs | null;
  active?: boolean | null;
  termsAccepted?: boolean | null;
  note?: string | null;
  status?: InvoiceStatusEnum | null;
}

export interface InvoiceUpdateNestedCustomerInputArgs {
  connect?: WhereUniqueInputArgs | null;
  create?: CustomerCreateInputArgs | null;
}

export interface InvoiceUpdateNestedJobTypeInputArgs {
  connect: WhereUniqueInputArgs;
}

export interface InvoiceUpdateNestedLineInputArgs {
  data: InvoiceLineUpdateInputArgs;
  where: WhereUniqueInputArgs;
}

export interface InvoiceUpdateNestedLinesInputArgs {
  update?: InvoiceUpdateNestedLineInputArgs[] | null;
  create?: InvoiceLineCreateInputArgs[] | null;
  delete?: WhereUniqueInputArgs[] | null;
}

export interface InvoiceUpdateNestedMileagesInputArgs {
  connect?: WhereUniqueInputArgs[] | null;
  disconnect?: WhereUniqueInputArgs[] | null;
}

export interface InvoiceUpdateNestedTaskInputArgs {
  connect?: WhereUniqueInputArgs | null;
  disconnect?: boolean | null;
}

export interface InvoiceWhereInputArgs {
  active?: BoolFilter | null;
  vacationPayment?: BoolFilter | null;
  currency?: StringFilter | null;
  customer?: CustomerWhereInputArgs | null;
  endDate?: DateTimeFilter | null;
  id?: BigIntFilter | null;
  note?: StringFilter | null;
  invoiceDate?: DateTimeFilter | null;
  paymentDueDays?: IntFilter | null;
  reference?: StringFilter | null;
  createdDate?: DateTimeFilter | null;
  updatedDate?: DateTimeFilter | null;
  startDate?: DateTimeFilter | null;
  status?: InvoiceStatusFilter | null;
  termsAccepted?: BoolFilter | null;
  user?: UserWhereInputArgs | null;
  task?: TaskWhereInputArgs | null;
  includeVat?: BoolFilter | null;
  jobType?: JobTypeWhereInputArgs | null;
  salaryId?: BigIntFilter | null;
  earlyPayment?: BoolFilter | null;
  OR?: (InvoiceWhereInputArgs | null)[] | null;
  query?: string | null;
}

export interface JobTypeOrderByInputArgs {
  classification?: SortOrder | null;
  description?: SortOrder | null;
  id?: SortOrder | null;
  name_en?: SortOrder | null;
  name_da?: SortOrder | null;
  createdDate?: SortOrder | null;
  updatedDate?: SortOrder | null;
  active?: SortOrder | null;
  group?: SortOrder | null;
  parentId?: SortOrder | null;
}

export interface JobTypeWhereInputArgs {
  active?: BoolFilter | null;
  classification?: StringFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  id?: any | null;
  group?: BoolFilter | null;
  parent?: JobTypeWhereInputArgs | null;
  createdDate?: DateTimeFilter | null;
  updatedDate?: DateTimeFilter | null;
}

export interface LanguageEnumFilter {
  equals?: LanguageEnum | null;
  in?: (LanguageEnum | null)[] | null;
  notIn?: (LanguageEnum | null)[] | null;
  not?: LanguageEnum | null;
}

export interface LanguageUpdateInputArgs {
  language: LanguageEnum;
}

export interface MileageCreateInputArgs {
  fromAddress: string;
  toAddress: string;
  startKm: any;
  endKm: any;
  date: any;
  createAsUserId?: any | null;
  mileageNumber?: number | null;
  task?: InvoiceCreateNestedTaskInputArgs | null;
  returnJourney?: boolean | null;
  linkedMileageId?: any | null;
}

export interface MileageFilter {
  some?: MileageWhereInputArgs | null;
  none?: MileageWhereInputArgs | null;
}

export interface MileageOrderByInputArgs {
  id?: SortOrder | null;
  distanceKm?: SortOrder | null;
  createdDate?: SortOrder | null;
  updatedDate?: SortOrder | null;
  fromAddress?: SortOrder | null;
  toAddress?: SortOrder | null;
  userId?: SortOrder | null;
  user?: UserOrderByInputArgs | null;
  status?: SortOrder | null;
  date?: SortOrder | null;
}

export interface MileageRequestCreateInputArgs {
  status: MileageRequestStatusEnum;
  createAsUserId?: any | null;
}

export interface MileageRequestOrderByInputArgs {
  id?: SortOrder | null;
  createdDate?: SortOrder | null;
  updatedDate?: SortOrder | null;
  userId?: SortOrder | null;
  user?: UserOrderByInputArgs | null;
  status?: SortOrder | null;
}

export interface MileageRequestStatusFilter {
  equals?: MileageRequestStatusEnum | null;
  in?: MileageRequestStatusEnum[] | null;
  notIn?: MileageRequestStatusEnum[] | null;
  not?: MileageRequestStatusEnum | null;
}

export interface MileageRequestUpdateInputArgs {
  status?: MileageRequestStatusEnum | null;
}

export interface MileageRequestWhereInputArgs {
  id?: BigIntFilter | null;
  createdDate?: DateTimeFilter | null;
  updatedDate?: DateTimeFilter | null;
  status?: MileageRequestStatusFilter | null;
  date?: DateTimeFilter | null;
  user?: UserWhereInputArgs | null;
  query?: string | null;
}

export interface MileageStatusFilter {
  equals?: MileageStatusEnum | null;
  in?: MileageStatusEnum[] | null;
  notIn?: MileageStatusEnum[] | null;
  not?: MileageStatusEnum | null;
}

export interface MileageUpdateInputArgs {
  fromAddress?: string | null;
  toAddress?: string | null;
  startKm?: any | null;
  endKm?: any | null;
  distanceKm?: any | null;
  status?: MileageStatusEnum | null;
  date?: any | null;
  active?: boolean | null;
  mileageNumber?: number | null;
  task?: InvoiceUpdateNestedTaskInputArgs | null;
}

export interface MileageWhereInputArgs {
  id?: BigIntFilter | null;
  createdDate?: DateTimeFilter | null;
  updatedDate?: DateTimeFilter | null;
  status?: MileageStatusFilter | null;
  fromAddress?: StringFilter | null;
  toAddress?: StringFilter | null;
  distanceKm?: FloatFilter | null;
  date?: DateTimeFilter | null;
  user?: UserWhereInputArgs | null;
  active?: BoolFilter | null;
  task?: TaskWhereInputArgs | null;
  salaryId?: BigIntFilter | null;
  invoiceId?: BigIntFilter | null;
  invoice?: InvoiceWhereInputArgs | null;
  OR?: (MileageWhereInputArgs | null)[] | null;
  query?: string | null;
}

export interface NoteCreateInputArgs {
  id?: any | null;
  userId?: any | null;
  targetUserId?: any | null;
  message?: string | null;
}

export interface NoteWhereInputArgs {
  user?: UserWhereInputArgs | null;
}

export interface OfferCreateInputArgs {
  createAsUserId?: any | null;
  projectName: string;
  customer: OfferCreateNestedCustomerInputArgs;
  services: OfferCreateNestedOfferServiceInputArgs;
  startDate: any;
  endDate?: any | null;
  currency: string;
  paymentTerm: string;
  depositAmount?: any | null;
  requiresSignature: boolean;
  message: string;
  status: OfferStatusEnum;
  customerContact: string;
  customerEmail: string;
  paymentTermOther?: string | null;
  includeVat: boolean;
}

export interface OfferCreateNestedCustomerInputArgs {
  connect?: WhereUniqueInputArgs | null;
  create?: CustomerCreateInputArgs | null;
}

export interface OfferCreateNestedOfferServiceInputArgs {
  create: OfferServiceCreateInputArgs[];
}

export interface OfferOrderByInputArgs {
  id?: SortOrder | null;
  createdDate?: SortOrder | null;
  updatedDate?: SortOrder | null;
  startDate?: SortOrder | null;
  endDate?: SortOrder | null;
  currency?: SortOrder | null;
  status?: SortOrder | null;
  paymentTerm?: SortOrder | null;
  depositAmount?: SortOrder | null;
  sentDate?: SortOrder | null;
  viewedDate?: SortOrder | null;
  approvedDate?: SortOrder | null;
  requiresSignature?: SortOrder | null;
  message?: SortOrder | null;
  customerId?: SortOrder | null;
  customer?: CustomerOrderByInputArgs | null;
  taskId?: SortOrder | null;
  task?: TaskOrderByInputArgs | null;
  invoiceId?: SortOrder | null;
  invoice?: InvoiceOrderByInputArgs | null;
  userId?: SortOrder | null;
  user?: UserOrderByInputArgs | null;
}

export interface OfferServiceCreateInputArgs {
  name: string;
  price: any;
  description: string;
  priceCategory: PriceCategoryEnum;
  quantity?: any | null;
  priceCategoryOther?: string | null;
}

export interface OfferServiceUpdateInputArgs {
  name?: string | null;
  price?: any | null;
  description?: string | null;
  priceCategory?: PriceCategoryEnum | null;
  quantity?: any | null;
  priceCategoryOther?: string | null;
}

export interface OfferStatusFilter {
  equals?: OfferStatusEnum | null;
  in?: OfferStatusEnum[] | null;
  notIn?: OfferStatusEnum[] | null;
  not?: OfferStatusEnum | null;
}

export interface OfferUpdateInputArgs {
  customer?: OfferCreateNestedCustomerInputArgs | null;
  services?: OfferUpdateNestedOfferServicesInputArgs | null;
  startDate?: any | null;
  endDate?: any | null;
  currency?: string | null;
  paymentTerm?: string | null;
  depositAmount?: any | null;
  requiresSignature?: boolean | null;
  message?: string | null;
  termsAccepted?: boolean | null;
  active?: boolean | null;
  projectName?: string | null;
  status?: OfferStatusEnum | null;
  customerContact?: string | null;
  customerEmail?: string | null;
  customerRejectionReason?: string | null;
  paymentTermOther?: string | null;
  includeVat?: boolean | null;
}

export interface OfferUpdateNestedOfferServiceInputArgs {
  data: OfferServiceUpdateInputArgs;
  where: WhereUniqueInputArgs;
}

export interface OfferUpdateNestedOfferServicesInputArgs {
  update?: OfferUpdateNestedOfferServiceInputArgs[] | null;
  create?: OfferServiceCreateInputArgs[] | null;
  delete?: WhereUniqueInputArgs[] | null;
}

export interface OfferWhereInputArgs {
  id?: BigIntFilter | null;
  endDate?: DateTimeFilter | null;
  startDate?: DateTimeFilter | null;
  status?: OfferStatusFilter | null;
  customer?: CustomerWhereInputArgs | null;
  currency?: StringFilter | null;
  createdDate?: DateTimeFilter | null;
  updatedDate?: DateTimeFilter | null;
  task?: TaskWhereInputArgs | null;
  invoice?: InvoiceWhereInputArgs | null;
  user?: UserWhereInputArgs | null;
  message?: StringFilter | null;
  requiresSignature?: BoolFilter | null;
  sentDate?: DateTimeFilter | null;
  viewedDate?: DateTimeFilter | null;
  approvedDate?: DateTimeFilter | null;
  depositAmount?: StringFilter | null;
  customerContact?: StringFilter | null;
  customerEmail?: StringFilter | null;
  paymentTerm?: StringFilter | null;
  termsAccepted?: BoolFilter | null;
  active?: BoolFilter | null;
  query?: string | null;
}

export interface PaymentCreateInputArgs {
  salaryIds?: number[] | null;
}

export interface PayrollDateWhere {
  userId?: BigIntFilter | null;
  isFinalized?: BoolFilter | null;
}

export interface PayrollOrderByInputArgs {
  id?: SortOrder | null;
  createdDate?: SortOrder | null;
  updatedDate?: SortOrder | null;
  startDate?: SortOrder | null;
  endDate?: SortOrder | null;
  finalizedDate?: SortOrder | null;
}

export interface PayrollUserOrderByInputArgs {
  createdDate?: SortOrder | null;
  updatedDate?: SortOrder | null;
  user?: UserOrderByInputArgs | null;
}

export interface PayrollUserWhereInputArgs {
  id?: BigIntFilter | null;
  createdDate?: DateTimeFilter | null;
  updatedDate?: DateTimeFilter | null;
  user?: UserWhereInputArgs | null;
  userId?: BigIntFilter | null;
  payroll?: PayrollWhereInputArgs | null;
  payrollId?: BigIntFilter | null;
  uuid?: StringFilter | null;
  zenegyEmployeePayrollUuid?: StringFilter | null;
  hasError?: BoolFilter | null;
  isUnpaid?: BoolFilter | null;
  isDeferred?: BoolFilter | null;
  query?: string | null;
}

export interface PayrollWhereInputArgs {
  id?: BigIntFilter | null;
  createdDate?: DateTimeFilter | null;
  updatedDate?: DateTimeFilter | null;
  startDate?: DateTimeFilter | null;
  endDate?: DateTimeFilter | null;
  user?: UserWhereInputArgs | null;
  uuid?: StringFilter | null;
  isFinalized?: BoolFilter | null;
  userId?: BigIntFilter | null;
  query?: string | null;
}

export interface RecordTypeFilter {
  equals?: RecordTypeEnum | null;
  in?: RecordTypeEnum[] | null;
  notIn?: RecordTypeEnum[] | null;
  not?: RecordTypeEnum | null;
}

export interface ReferralCreateInputArgs {
  email: string;
  message: string;
  createAsUserId?: any | null;
}

export interface ReferralOrderByInputArgs {
  id?: SortOrder | null;
  createdDate?: SortOrder | null;
  updatedDate?: SortOrder | null;
  user?: UserOrderByInputArgs | null;
  email?: SortOrder | null;
  userName?: UserOrderByInputArgs | null;
  status?: SortOrder | null;
}

export interface ReferralStatusFilter {
  equals?: ReferralStatusEnum | null;
  in?: ReferralStatusEnum[] | null;
  notIn?: ReferralStatusEnum[] | null;
  not?: ReferralStatusEnum | null;
}

export interface ReferralWhereInputArgs {
  id?: BigIntFilter | null;
  createdDate?: DateTimeFilter | null;
  updatedDate?: DateTimeFilter | null;
  user?: UserWhereInputArgs | null;
  status?: ReferralStatusFilter | null;
  email?: StringFilter | null;
  referredUser?: UserWhereInputArgs | null;
  query?: string | null;
}

export interface RejectedDeduction {
  id: any;
  reason: string;
}

export interface RoleFilter {
  equals?: RoleEnum | null;
  in?: RoleEnum[] | null;
  notIn?: RoleEnum[] | null;
  not?: RoleEnum | null;
}

export interface SalaryCreateInputArgs {
  paymentDate: any;
  invoices?: any[] | null;
  approvedDeductions?: any[] | null;
  rejectedDeductions?: RejectedDeduction[] | null;
  approvedMileages?: any[] | null;
  rejectedMileages?: any[] | null;
  userId?: any | null;
}

export interface SalaryOrderByInputArgs {
  id?: SortOrder | null;
  paymentDate?: SortOrder | null;
  createdDate?: SortOrder | null;
  updatedDate?: SortOrder | null;
  userId?: SortOrder | null;
  status?: SortOrder | null;
  user?: UserOrderByInputArgs | null;
  grossIncome?: SortOrder | null;
  paymentAmount?: SortOrder | null;
}

export interface SalaryStatusFilter {
  equals?: SalaryStatusEnum | null;
  in?: SalaryStatusEnum[] | null;
  notIn?: SalaryStatusEnum[] | null;
  not?: SalaryStatusEnum | null;
}

export interface SalaryUpdateInputArgs {
  id?: any | null;
  paymentDate?: any | null;
  status?: SalaryStatusEnum | null;
  invoices?: any[] | null;
  deductions?: any[] | null;
  mileages?: any[] | null;
}

export interface SalaryWhereInputArgs {
  id?: BigIntFilter | null;
  createdDate?: DateTimeFilter | null;
  updatedDate?: DateTimeFilter | null;
  paymentDate?: DateTimeFilter | null;
  user?: UserWhereInputArgs | null;
  status?: SalaryStatusFilter | null;
  invoices?: InvoiceListRelationWhereArgs | null;
  deductions?: DeductionListRelationWhereArgs | null;
  active?: BoolFilter | null;
  payrollUserId?: BigIntFilter | null;
  query?: string | null;
}

export interface ServiceLogOrderByInputArgs {
  createdDate?: SortOrder | null;
}

export interface ServiceLogWhereInputArgs {
  id?: BigIntFilter | null;
  createdDate?: DateTimeFilter | null;
  updatedDate?: DateTimeFilter | null;
  recordId?: BigIntFilter | null;
  recordType?: RecordTypeFilter | null;
  service?: StringFilter | null;
  status?: StringFilter | null;
}

export interface StringFilter {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  notIn?: string[] | null;
  startsWith?: string | null;
  not?: string | null;
}

export interface SupportPostOrderByInputArgs {
  id?: SortOrder | null;
  createdDate?: SortOrder | null;
  updatedDate?: SortOrder | null;
  orderId?: SortOrder | null;
}

export interface SupportPostTagWhereInputArgs {
  equals?: string | null;
  in?: string[] | null;
  notIn?: string[] | null;
  not?: string | null;
}

export interface SupportPostTypeWhereInputArgs {
  equals?: SupportPostEnum | null;
  in?: SupportPostEnum[] | null;
  notIn?: SupportPostEnum[] | null;
  not?: SupportPostEnum | null;
}

export interface SupportPostWhereInputArgs {
  active?: BoolFilter | null;
  id?: BigIntFilter | null;
  createdDate?: DateTimeFilter | null;
  updatedDate?: DateTimeFilter | null;
  tags?: SupportPostTagWhereInputArgs | null;
  type?: SupportPostTypeWhereInputArgs | null;
  language?: LanguageEnumFilter | null;
}

export interface TaskArchiveInputArgs {
  archived: boolean;
}

export interface TaskCreateInputArgs {
  endDate: any;
  expectedHours: any;
  reference?: string | null;
  startDate: any;
  termsAccepted: boolean;
  isRetainer: boolean;
  title: string;
  customer: TaskCreateNestedCustomerInputArgs;
  jobType: TaskCreateNestedJobTypeInputArgs;
  status: TaskStatusEnum;
  description?: string | null;
  customerMessage?: string | null;
  customerContact?: string | null;
  customerEmail?: string | null;
  paymentType?: TaskPaymentTypeEnum | null;
  paymentAmount?: any | null;
  currency?: string | null;
  createAsUserId?: any | null;
  poNumber?: string | null;
  paymentTerm: PaymentTermEnum;
  paymentTermDays?: number | null;
  paymentTermOther?: string | null;
  offerId?: any | null;
  allowMileages?: boolean | null;
  requestAllowMileages?: boolean | null;
  showCustomerPrice?: boolean | null;
  timeRegistrationType: TaskTimeRegistrationTypeEnum;
}

export interface TaskCreateNestedCustomerInputArgs {
  connect?: WhereUniqueInputArgs | null;
  create?: CustomerCreateInputArgs | null;
}

export interface TaskCreateNestedJobTypeInputArgs {
  connect: WhereUniqueInputArgs;
}

export interface TaskOrderByInputArgs {
  id?: SortOrder | null;
  active?: SortOrder | null;
  expectedHours?: SortOrder | null;
  reference?: SortOrder | null;
  createdDate?: SortOrder | null;
  updatedDate?: SortOrder | null;
  startDate?: SortOrder | null;
  endDate?: SortOrder | null;
  termsAccepted?: SortOrder | null;
  title?: SortOrder | null;
  customerId?: SortOrder | null;
  customer?: CustomerOrderByInputArgs | null;
  jobTypeId?: SortOrder | null;
  jobType?: JobTypeOrderByInputArgs | null;
  userId?: SortOrder | null;
  user?: UserOrderByInputArgs | null;
  status?: SortOrder | null;
  isRetainer?: SortOrder | null;
}

export interface TaskStatusFilter {
  equals?: TaskStatusEnum | null;
  in?: TaskStatusEnum[] | null;
  notIn?: TaskStatusEnum[] | null;
  not?: TaskStatusEnum | null;
}

export interface TaskUpdateInputArgs {
  endDate?: any | null;
  expectedHours?: any | null;
  reference?: string | null;
  startDate?: any | null;
  termsAccepted?: boolean | null;
  isRetainer?: boolean | null;
  active?: boolean | null;
  title?: string | null;
  customer?: TaskUpdateNestedCustomerInputArgs | null;
  jobType?: TaskUpdateNestedJobTypeInputArgs | null;
  status?: TaskStatusEnum | null;
  description?: string | null;
  customerMessage?: string | null;
  customerContact?: string | null;
  customerEmail?: string | null;
  paymentType?: TaskPaymentTypeEnum | null;
  paymentAmount?: any | null;
  currency?: string | null;
  archive?: boolean | null;
  poNumber?: string | null;
  paymentTerm?: PaymentTermEnum | null;
  paymentTermDays?: number | null;
  paymentTermOther?: string | null;
  allowMileages?: boolean | null;
  requestAllowMileages?: boolean | null;
  showCustomerPrice?: boolean | null;
  timeRegistrationType?: TaskTimeRegistrationTypeEnum | null;
}

export interface TaskUpdateNestedCustomerInputArgs {
  connect?: WhereUniqueInputArgs | null;
  create?: CustomerCreateInputArgs | null;
}

export interface TaskUpdateNestedJobTypeInputArgs {
  connect: WhereUniqueInputArgs;
}

export interface TaskWhereInputArgs {
  active?: BoolFilter | null;
  customer?: CustomerWhereInputArgs | null;
  endDate?: DateTimeFilter | null;
  expectedHours?: IntFilter | null;
  id?: BigIntFilter | null;
  jobType?: JobTypeWhereInputArgs | null;
  reference?: StringFilter | null;
  createdDate?: DateTimeFilter | null;
  updatedDate?: DateTimeFilter | null;
  startDate?: DateTimeFilter | null;
  status?: TaskStatusFilter | null;
  termsAccepted?: BoolFilter | null;
  isRetainer?: BoolFilter | null;
  allowMileages?: BoolFilter | null;
  requestAllowMileages?: BoolFilter | null;
  showCustomerPrice?: BoolFilter | null;
  archived?: BoolFilter | null;
  title?: StringFilter | null;
  user?: UserWhereInputArgs | null;
  invoices?: InvoiceListRelationWhereArgs | null;
  isOverdue?: BoolFilter | null;
  query?: string | null;
}

export interface UpcomingSalaryOrderByInputArgs {
  date?: SortOrder | null;
  userId?: SortOrder | null;
  user?: UserOrderByInputArgs | null;
}

export interface UpcomingSalaryWhereInputArgs {
  date?: DateTimeFilter | null;
  user?: UserWhereInputArgs | null;
  invoices?: InvoiceListRelationWhereArgs | null;
  deductions?: DeductionListRelationWhereArgs | null;
  query?: string | null;
}

export interface UpdatePayrollUserInputArgs {
  id?: any | null;
  extraPaymentDate?: any | null;
  status?: PayrollUserStatusEnum | null;
}

export interface UserOrderByInputArgs {
  active?: SortOrder | null;
  address?: AddressOrderByInputArgs | null;
  internalId?: SortOrder | null;
  id?: SortOrder | null;
  firstName?: SortOrder | null;
  lastName?: SortOrder | null;
  displayName?: SortOrder | null;
  phoneNumber?: SortOrder | null;
  title?: SortOrder | null;
  email?: SortOrder | null;
  role?: SortOrder | null;
  referral?: SortOrder | null;
  freelancerSituation?: SortOrder | null;
  userSpecifiedReferral?: SortOrder | null;
  lastActive?: SortOrder | null;
  createdDate?: SortOrder | null;
  updatedDate?: SortOrder | null;
  engagementScore?: SortOrder | null;
  latestSentInvoiceDate?: SortOrder | null;
  canUpdateVacationPayment?: SortOrder | null;
}

export interface UserUpdateInputArgs {
  language?: string | null;
  unit?: string | null;
  currency?: string | null;
  jobType?: ConnectDisconnectUniqueInputArgs | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  salaryPaymentType?: SalaryPaymentTypeEnum | null;
  salaryPaymentValue?: number | null;
  salaryPaymentDay?: number | null;
  salaryPaymentDate?: any | null;
  baseRate?: number | null;
  vacationPayment?: boolean | null;
  address?: string | null;
  city?: string | null;
  postalCode?: string | null;
  countryId?: any | null;
  userSpecifiedReferral?: string | null;
  freelancerSituation?: string | null;
  bankName?: string | null;
  bankAccount?: string | null;
  bankRegistration?: string | null;
  bankAccountType?: BankAccountTypeEnum | null;
  accountSetupComplete?: boolean | null;
  active?: boolean | null;
  identityProvider?: string | null;
  identityVerified?: boolean | null;
  referral?: string | null;
  currentYearDistanceKm?: any | null;
  contractSignatureProvider?: string | null;
  personId?: string | null;
  credits?: number | null;
  taxCard?: TaxCardEnum | null;
  locked?: boolean | null;
  ignoreInPayroll?: boolean | null;
  lockReason?: string | null;
  notificationFrequency?: NotificationFrequencyEnum | null;
  taskAutoApprovalRule?: AutoApprovalRuleEnum | null;
}

export interface UserWhereInputArgs {
  active?: BoolFilter | null;
  address?: WhereUniqueInputArgs | null;
  id?: BigIntFilter | null;
  createdDate?: DateTimeFilter | null;
  updatedDate?: DateTimeFilter | null;
  lastActive?: DateTimeFilter | null;
  latestSentInvoiceDate?: DateTimeFilter | null;
  referral?: StringFilter | null;
  email?: StringFilter | null;
  phoneNumber?: StringFilter | null;
  lastName?: StringFilter | null;
  displayName?: StringFilter | null;
  firstName?: StringFilter | null;
  role?: RoleFilter | null;
  emailVerified?: BoolFilter | null;
  customers?: CustomerWhereInputArgs | null;
  mileages?: MileageFilter | null;
  hasApprovedTasks?: BoolFilter | null;
  query?: string | null;
}

export interface WhereUniqueInputArgs {
  id: any;
}

export interface WorkLogCreateInputArgs {
  startTime: any;
  task?: ConnectUniqueInputArgs | null;
  durationMinutes: number;
  notes?: string | null;
  createAsUserId?: any | null;
}

export interface WorkLogOrderByInputArgs {
  id?: SortOrder | null;
  task?: TaskOrderByInputArgs | null;
  startTime?: SortOrder | null;
  durationMinutes?: SortOrder | null;
  createdDate?: SortOrder | null;
  updatedDate?: SortOrder | null;
  userId?: SortOrder | null;
  user?: UserOrderByInputArgs | null;
}

export interface WorkLogUpdateInputArgs {
  startTime?: any | null;
  task?: ConnectUniqueInputArgs | null;
  durationMinutes?: number | null;
  notes?: string | null;
  createAsUserId?: any | null;
  active?: boolean | null;
  sendNotification?: boolean | null;
  status?: WorkLogStatusEnum | null;
}

export interface WorkLogWhereInputArgs {
  active?: BoolFilter | null;
  durationMinutes?: IntFilter | null;
  id?: BigIntFilter | null;
  createdDate?: DateTimeFilter | null;
  updatedDate?: DateTimeFilter | null;
  startTime?: DateTimeFilter | null;
  notes?: StringFilter | null;
  user?: UserWhereInputArgs | null;
  task?: TaskWhereInputArgs | null;
  query?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

import { useFormContext } from 'react-hook-form';
import { cn } from '~/shadcn/utils';
import Card from '~/shadcn/ui/card';
import { RadioGroup, RadioGroupItem } from '~/shadcn/ui/radio-group';
import { FormControl, FormField, FormItem, FormMessage } from '~/shadcn/ui/form';
import RequiredSymbol from '~/features/forms/components/RequiredSymbol';

export interface CardRadioGroupOption {
  value: string;
  label: string;
  Icon: JSX.Element;
  description?: string;
  render?: (props: { selected: boolean; fieldValue: string }) => JSX.Element | null;
}

export interface CardRadioGroupProps {
  name: string;
  options: CardRadioGroupOption[];
  className?: string;
  autoFocus?: boolean;
  label?: string;
  required?: boolean;
  legacyError?: boolean;
}

export default function CardRadioGroup({
  options,
  name,
  className,
  autoFocus,
  label,
  required,
  legacyError = false
}: CardRadioGroupProps) {
  const { control } = useFormContext();
  return (
    <>
      {label && (
        <label htmlFor={name} className="tw-text-[.9rem] tw-font-semibold tw-mb-2">
          {label} {required && <RequiredSymbol />}
        </label>
      )}
      <FormField
        control={control}
        name={name}
        render={({ field }) => (
          <FormItem className="tw-w-full">
            <FormControl>
              <RadioGroup
                onValueChange={field.onChange}
                defaultValue={field.value}
                className={cn('tw-w-full sm:tw-grid-cols-2', className)}
              >
                {options.map((option, index) => {
                  const selected = option.value === field.value;
                  return (
                    <FormItem key={option.value}>
                      <Card
                        asButton
                        className={cn(
                          // Layout
                          'tw-flex tw-flex-row tw-w-full tw-justify-start tw-content-center tw-items-center !tw-py-3 tw-gap-5',
                          'tw-cursor-pointer tw-outline-none',
                          // Focus
                          'focus-within:tw-ring-input-ring focus-within:tw-ring-2 focus-within:tw-ring-offset-2'
                        )}
                        tabIndex={0}
                        autoFocus={autoFocus && index === 0}
                        variant={selected ? 'success' : 'white'}
                        onClick={(event) => {
                          event.preventDefault();
                          field.onChange(option.value);
                        }}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter' || event.key === '') {
                            event.preventDefault();
                            field.onChange(option.value);
                          }
                        }}
                        role="radio"
                        aria-checked={selected}
                        value={option.value}
                        data-testid={`${option.value.toLowerCase().replaceAll(' ', '-')}-radio-button`}
                      >
                        <FormControl>
                          <RadioGroupItem value={option.value} className="tw-hidden" />
                        </FormControl>
                        <span
                          className={cn(
                            'tw-my-4 tw-w-8 tw-h-8',
                            // Text
                            { 'tw-text-success': selected, 'tw-text-primary': !selected }
                          )}
                        >
                          {option.Icon}
                        </span>
                        <div
                          className={cn(
                            // Layout
                            'tw-inline-block tw-w-px tw-self-stretch',
                            // Background
                            { 'tw-bg-neutral-200': !selected, 'tw-bg-green-200': selected }
                          )}
                        />
                        <div
                          className={cn('tw-flex tw-flex-col s', {
                            'tw-hidden': selected && !!option.render
                          })}
                        >
                          {option.description && (
                            <span className="tw-text-xs tw-text-muted tw-px-0 tw-mx-0 tw-text-left">
                              {option.description}
                            </span>
                          )}
                          <p
                            className={cn('tw-m-0 tw-text-start', {
                              'tw-text-success tw-font-semibold': selected
                            })}
                          >
                            {option.label}
                          </p>
                        </div>
                        {option.render?.({ selected, fieldValue: field.value })}
                      </Card>
                    </FormItem>
                  );
                })}
              </RadioGroup>
            </FormControl>
            <FormMessage legacyError={legacyError} />
          </FormItem>
        )}
      />
    </>
  );
}
